import * as React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import styles from "./App.module.scss";
import HomePage from "./pages/HomePage/HomePage";
import PageNotFoundPage from "./pages/PageNotFoundPage/PageNotFoundPage";

class App extends React.Component {
  render() {
    return (
        <div className={styles.App}>
          <Router basename={process.env.PUBLIC_URL}>
            <div>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="*" element={<PageNotFoundPage />} />
              </Routes>
            </div>
          </Router>
        </div>
    );
  }
}

export default App;