import React from "react";
import sharedStyles from '../../assets/styles/SharedStyles.module.scss'

// a basic form
const SimpleForm = ({ status, message, className, style, onSubmitted }) => {
    let input;
    const submit = () =>
        input &&
        input.value.indexOf("@") > 0 &&
        input.value.indexOf(".") > input.value.indexOf("@") &&
        onSubmitted({
            EMAIL: input.value
        });

    return (
        <div className={className} style={style}>
            {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
            {status === "error" && (
                <div
                    style={{ color: "red" }}
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            )}
            {status === "success" && (
                <div
                    style={{ color: "green" }}
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            )}
            <input
                ref={node => (input = node)}
                type="email"
                placeholder="Your email"
                className={sharedStyles.input}
            />
            <button onClick={submit} className={sharedStyles.button}>Submit</button>
        </div>
    );
};

export default SimpleForm;