import * as React from "react";
import Subscribe from "../../components/Subscribe/Subscribe";
import sharedStyles from "../../assets/styles/SharedStyles.module.scss"
import Countdown from "../../components/Countdown/Countdown";
import Header from "../../components/Header/Header";

class HomePage extends React.Component {
    url = 'https://jointsolutions.us21.list-manage.com/subscribe/post?u=cb6768b92b21d6709aef7dd32&amp;id=6ef0e17bd0&amp;f_id=00ccc5e1f0'
    render() {
        return (
            <div>
                <Header />
                <div className={sharedStyles.countdown}>
                    <Countdown dateTo='May 01, 2023 18:00:00 GMT+02:00'/>
                </div>
                <div className={sharedStyles.subscribe}>
                    <p className={sharedStyles.subscribeText}>Subscribe to our newsletter.</p>
                    <Subscribe url={this.url}/>
                </div>
            </div>
        );
    }
}

export default HomePage;