import * as React from "react";
import Button from '@mui/material/Button';
import styles from '../../assets/styles/SharedStyles.module.scss';

class PageNotFoundPage extends React.Component {
    render() {
        return (
            <div className={styles.pnfPage}>
                <h1>404</h1>
                <h2>We can't find that page</h2>
                <p>We're fairly sure that page used to be here, but seems to have gone missing. We do apologise on it's behalf.</p>
                <Button variant="contained" href="/">
                    Home
                </Button>
            </div>
        );
    }
}

export default PageNotFoundPage;